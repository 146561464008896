import { NgModule } from '@angular/core';
import { NgxNouisliderComponent } from './ngx-nouislider.component';

@NgModule({
  imports: [
  ],
  declarations: [NgxNouisliderComponent],
  exports: [NgxNouisliderComponent]
})
export class NgxNouisliderModule { }
