import { NouiFormatter } from "./noui-formatter";

export class DefaultFormatter implements NouiFormatter {
	to(value: number): string {
    return String(parseFloat(parseFloat(String(value)).toFixed(2)));
  };

  from(value: string): number {
    return parseFloat(value);
}
}
